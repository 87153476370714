<template>
  <div>
    <div class="banner">
      <el-row justify="flex" class="con">
        <el-col :span="14" class="v_upinfo">
          <div class="bili-avatar">
            <el-avatar class="img" :src="this.$store.state.user.avatar"></el-avatar>
          </div>
          <div class="up-info_right">
            <div class="username">
              {{ this.$store.state.user.name }}
            </div>
            <div class="userIntroduce">
              {{ this.$store.state.user.introduction }}
            </div>
          </div>
        </el-col>
        <el-col :span="10">

        </el-col>
      </el-row>
    </div>
    <div class="wrap">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="签到" name="first">
          <clock></clock>
        </el-tab-pane>
        <el-tab-pane label="班级管理" name="second">
          <crowd></crowd>
        </el-tab-pane>
        <el-tab-pane label="课程管理" name="third">
          <show-course></show-course>
        </el-tab-pane>
        <el-tab-pane label="博客管理" name="fourth">
          <show-blog></show-blog>
        </el-tab-pane>
        <el-tab-pane label="我的收藏" name="fifth">
          <collect></collect>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import ShowCourse from "@/views/self/show-course";
import showBlog from "@/views/self/show-blog";
import Collect from "@/views/self/collect";
import clock from "@/views/clock/index"
import Crowd from "@/views/self/crowd";
export default {
  name: "Self",
  components: {Crowd, Collect, ShowCourse,showBlog,clock},
  data() {
    return {
      activeName: 'first'
    };
  },
  methods: {
    handleClick(tab, event) {
      // console.log(tab, event);
    }
  }
}
</script>

<style scoped>
/* 最上面的一行信息*/
.banner {
  height: 150px;
  width: 100%;
  background: url("../../assets/image/bgc.png") no-repeat top;
  /*background-color: #0d4473;*/
}

.con {
  margin: 0 auto;
  width: 70%;
  height: 100%;
}

/* 头像区*/
.v_upinfo {
  display: flex;
  height: 100%;
  margin-bottom: 6px;
}

.bili-avatar {
  margin-right: 10px;
  display: flex;
}

.img {
  margin: auto;
  height: 100px;
  width: 100px;
  /*display: block;*/
}

.up-info_right div {
  text-align: left;
  line-height: 50px;
  height: 50px;
}

.username {
  margin-top: 20px;
  font-size: 26px;
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 370px;
  min-width: 100px;
}
.userIntroduce{
  font-size: 26px;
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 370px;
  min-width: 100px;
  margin-bottom: 20px;
}

  /* 主体部分信息*/
.wrap  {
  height: 100%;
  width: 70%;
  min-height: 400px;
  margin: 10px auto;
  padding: 20px;
  background-color: #fff;
}
</style>
