<template>
  <div>
    <el-row v-for="(item,index) in blog" :key="index">
      <el-col :span="20">
        <div class="blog">
          <el-card :body-style="{ padding: '0px' }" class="card" shadow="hover">
            <el-row :gutter="20">
              <el-col :span="10">
                <div class="left">
                  <!--                  右边图像-->
                  <!--            <div class="left_type">tag</div>-->
                  <el-image :src="item.articleImage" :fit="fill" class="img">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </div>
              </el-col>
              <el-col :span="14">
                <el-container class="right">
                  <el-header height="40px">
                    <h1>{{ item.articleTitle }}</h1>
                  </el-header>
                  <el-main class="ma">
                    <p class="content">{{ item.articleContext }}</p>
                  </el-main>
                  <el-footer height="40px">
                    <div class="bottom">
                      <div>
                        <i class="el-icon-alarm-clock"></i>
                        {{item.gmtCreate}}
                      </div>
                      <div>
                        <i class="el-icon-view"></i>
                        {{ item.articleViews}}浏览
                      </div>
                      <div>
                        <i class="el-icon-chat-dot-round"></i>
                        {{ item.articleStar }}收藏
                      </div>
                      <div>
                        <i class="el-icon-lollipop"></i>
                        {{ item.articleGood }} 点赞
                      </div>
                    </div>
                  </el-footer>
                </el-container>
              </el-col>
            </el-row>
          </el-card>
        </div>
      </el-col>
      <el-col :span="4">
        <el-button @click.native="deleteArticle(item.id)" type="primary">删除文章</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import {getArticleByUserId, deleteArticle} from "@/api/article";
import {utc2beijing} from "@/utils/time";

export default {
  name: "Show",
  mounted() {
    this.getArticle();
  },
  methods: {
    utc2beijing,
    deleteArticle(id) {
      deleteArticle(id).then(result => {
        this.$message({
          message: result.data.article,
          duration: 2 * 1000
        });
        this.getArticle();
      })
    },
    getArticle() {
      // 获取该用户发表的文章
      getArticleByUserId(this.$store.state.user.id).then(result => {
        this.blog = result.data.article;
      }).catch(error => {
        // 输出错误信息
        this.$message(error.message)
      })
    }
  },
  data() {
    return {
      blog: [],
      fill: "fill"
    }
  }
}
</script>

<style scoped>
.blog{
  margin-bottom: 10px;
}
.card{
  height: 8rem;
  margin: 0 auto;
  padding: 0;
}

.left {
  display: block;
  margin: auto;
  position: relative;
}
.left_type {
  position: absolute;
  left: 0;
  top: 0;
  height: 1.5rem;
  width: 5.625rem;
  background-color: #0078ff;;
  color: white;
  line-height: 1.5rem;
  font-weight: 600;
  z-index: 100;
}
.img {
  display: block;
  height: 8rem;
  width: 100%;
}


/*   文章右边的部分 */
.right {
  height: 8rem;
}
.right h1 {
  margin-top: 10px;
  text-align: left;
  font-weight: 600;
  font-size: 1.5rem;
}

/* 文章的内容*/
.ma{
  overflow: hidden;
  padding: 0 20px;
}
.content {
  text-align: left;
  display: block;
  font-size: .9em;
  color: #737373;
  padding: 0;
  overflow: hidden;
  white-space: normal;
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.bottom {
  display: flex;
  width: 100%;
  height: 100%;
}
.bottom div {
  /*flex: 1;*/
  font-size: 1.1em;
  text-align: left;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  margin-right: 4px;
}
</style>
