import service from "@/utils/request";

/**
 * 添加文章信息
 * @param article 文章信息
 * @returns {AxiosPromise}
 */
export function addArticle(article) {
    return service(
        {
            url: 'article/addArticle',
            method: 'post',
            header: {
                "Content-Type": "application/json" //如果写成contentType会报错
            },
            data: article
        })
}

/**
 *
 * 获取所有文章信息
 * @returns {AxiosPromise}
 */
export function getArticleAll(current) {
    return service(
        {
            url: 'article/selectAllArticle',
            method: 'post',
            params: {
                current
            }
        })
}

/**
 * 根据用户的id 来获取文章信息
 * @returns {AxiosPromise}
 */
export function getArticleByUserId(userId) {
    return service(
        {
            url: 'article/selectByUserId',
            method: 'post',
            params: {userId}
        })
}

/**
 * 根据文章来获取信息
 * @param id 文章id
 * @returns {AxiosPromise}
 */
export function getArticleById(id) {
    return service(
        {
            url: 'article/selectByArticleId',
            method: 'post',
            params: {id}
        })
}

/**
 * 根据文章id 来更新
 * @param userId
 * @returns {AxiosPromise}
 */
export function updateArtice(article) {
    return service(
        {
            url: 'article/updateArticle',
            method: 'post',
            header: {
                "Content-Type": "application/json" //如果写成contentType会报错
            },
            data: article
        })
}

/**
 * 点赞文章信息
 * @param userId 用户id
 * @param articleId 文章id
 * @returns {AxiosPromise}
 */
export function goodArtice(userId, articleId) {
    return service(
        {
            url: 'article/goodArticle',
            method: 'post',
            params: {
                userId,
                articleId
            }
        })
}

/**
 * 删除文章
 * @param id 文章id
 * @returns {AxiosPromise}
 */
export function deleteArticle(id) {
    return service(
        {
            url: 'article/deleteArticle',
            method: 'post',
            params: {
                id
            }
        })
}

