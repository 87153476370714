<template>
  <div>
    <el-row :gutter="10" class="classbar">
      <el-col :span="6" v-for="(item, index) in course.records" :key="index" class="item" >
        <el-card :body-style="{ padding: '0px' }" class="card" shadow="hover">
          <el-image
              class="image"
              :src="item.courseUrl"
              fit="fill"></el-image>
          <div class="show">
            <div class="name c">课程名字: {{ item.courseName }}</div>
            <div class="bottom c">
              <div>简介:{{ item.courseIntroduction }}</div>
            </div>

            <el-row class="video-toolbar-v1" type="flex">
              <el-col :span="6" @click.native="clickGood">
                <i class="fa fa-thumbs-o-up"></i>
                <span>{{ item.courseViews }}</span>
                <!--            <span>点赞</span>-->
              </el-col>
              <el-col :span="6">
                <i class="fa fa-thumbs-o-down"></i>
                <span>{{ item.courseGood }}</span>
              </el-col>
              <el-col :span="6" @click.native="clickCollect">
                <i class="el-icon-star-off"></i>
                <span>{{ item.courseBad }}</span>
              </el-col>
              <el-col :span="6">
                <i class="fa fa-share-square-o"></i>
                <span>
                  {{ item.courseTotal }}
                </span>
              </el-col>
            </el-row>
            <div>
              <el-button type="danger" @click="deleteMyCourse(item.id)">删除课程</el-button>
              <el-button type="primary" @click="clickUpdate(item)">修改课程</el-button>
            </div>
          </div>
        </el-card>
      </el-col>

    </el-row>
    <el-pagination
        background
        :current-page="course.current"
        :page-size="course.size"
        @current-change="getCourse"
        layout="prev, pager, next"
        :total="course.total">
    </el-pagination>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="60%"
    >
      <el-form ref="courseForm" :model="form" :rules="rules" label-width="200px">
        <el-form-item label="请输入课程名称" prop="courseName">
          <el-input v-model="form.courseName"></el-input>
        </el-form-item>
        <el-form-item label="请选择分区" prop="courseClassification">
          <el-select v-model="form.courseClassification" placeholder="请选择视频分区">
            <el-option label="考研" value="1"></el-option>
            <el-option label="健身" value="2"></el-option>
            <el-option label="娱乐" value="3"></el-option>
            <el-option label="影视" value="4"></el-option>
            <el-option label="舞蹈" value="5"></el-option>
            <el-option label="鬼畜" value="6"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="课程封面" class="h" prop="courseUrl">
          <div>
            <el-upload
                class="avatar-uploader"
                :action=ossUrl
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
            >
              <el-avatar shape="square" v-if="form.courseUrl" :src="form.courseUrl" class="avatar img">
              </el-avatar>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </el-form-item>

        <el-form-item label="请选择标签" prop="tags">
          <el-tag
              :key="tag"
              v-for="tag in form.tags"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)" class="tag">
            {{ tag }}
          </el-tag>
          <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
          >
          </el-input>
          <el-button v-else class="button-new-tag" size="small" @click="showInput">+ New Tag</el-button>
        </el-form-item>

        <el-form-item label="资源类型" prop="courseType">
          <el-radio-group v-model="form.courseType">
            <el-radio label="1">自制</el-radio>
            <el-radio label="2">转载</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="简介" prop="courseIntroduction">
          <el-input type="textarea" v-model="form.courseIntroduction"></el-input>
        </el-form-item>

        <el-form-item prop="video" label="视屏">
          <el-upload
              class="upload-demo"
              :on-success="success"
              drag
              :action=vodUrl
              multiple>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">上传视频</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="update">确定修改</el-button>
     </span>
    </el-dialog>

  </div>
</template>

<script>
import {getCourseByUserId, deleteCourse, updateCourse} from "@/api/course";

export default {
  name: "ShowCourse",
  data() {
    return {
      course: [],
      dialogVisible: false,
      inputVisible: false,
      inputValue: '',
      vodUrl: this.Global.BASE_URL + ':8001/service-vod/video/upload',
      ossUrl: this.Global.BASE_URL + ":8001/service-oss/upload",
      form: {
        id: "",
        userId: this.$store.state.user.id,
        courseName: '',
        courseType: "",
        courseClassification: '',
        courseUrl: '',
        courseIntroduction: "",
        video: [],
        tags: [],
      },
      rules: {
        courseUrl: [{required: true, message: '请添加封面', trigger: 'blur'}],
        courseName: [
          {required: true, message: '请输入活动名称', trigger: 'blur'},
        ],
        courseType: [
          {required: true, message: '请输入资源类型', trigger: 'change'}
        ],
        courseClassification: [
          {required: true, message: '请至少选择一个分区', trigger: 'change'}
        ],
        courseIntroduction: [
          {required: true, message: '请填写活动简介', trigger: 'blur'}
        ],
        tags: [
          {type: 'array', required: true, message: '请至少添加一个标签', trigger: 'blur'}
        ],
        video: [
          {type: 'array', required: true, message: "请至少添加一个视屏", trigger: 'change'}
        ],
      }
    }
  },
  mounted() {
    // 刷新课程
    this.getCourse(1);
  },
  methods: {
    clickUpdate(item) {
      this.dialogVisible = true;
      this.form.id = item.id;
      this.form.courseClassification = item.courseClassification;
      this.form.courseName = item.courseName;
      this.form.courseUrl = item.courseUrl;
      this.form.courseIntroduction = item.courseIntroduction
    },
    // 课程添加成功
    update() {
      this.$refs['courseForm'].validate((valid) => {
        if (!valid) {
          this.$message({
            message: "请填写数据",
            type: "error",
            duration: 2 * 1000
          })
          return false;
        } else {
          updateCourse(this.form).then(result => {
            this.dialogVisible = false;
            this.$message({
              type: 'success',
              message: result.data.course,
              duration: 2 * 1000
            })
            this.getCourse();
          }).catch(error => {
            this.$message(error.message)
          })
        }
      });
    },
    handleClose(tag) {
      this.form.tags.splice(this.form.tags.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.form.tags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    // 上传图片
    handleAvatarSuccess(res, file) {
      this.form.courseUrl = res.data.url;
    },

    // 上传视屏成功视频信息
    success(response) {
      console.log(response)
      this.form.video.push(response.data.videoId)
    },

    deleteMyCourse(id) {
      console.log("删除视频" + id)
      deleteCourse(id).then(result => {
        this.$message({
          type: "success",
          message: result.data.course,
          duration: 2 * 1000
        })
        this.getCourse(1);
      }).catch(error => {
        this.$message({
          type: "error",
          message: error.message,
          duration: 2 * 1000
        })
      })
    },

    getCourse(number) {
      getCourseByUserId(this.$store.state.user.id, number).then(result => {
        console.log(result)
        // 设置 课程信息
        this.course = result.data.course;
      }).catch(error => {
        console.log(error.message)
      })
    }
  }
}
</script>

<style scoped>
.card {
  padding: 0;
  margin-bottom: 10px;
}
/*图片*/
.card  .image {
  height: 9rem;
}

.c {
  height: 1.875rem;
  line-height: 1.875rem;
}
.show{
  padding: 4px;
}
/*收藏的部分*/
.video-toolbar-v1 {
  height: 30px;
  text-align: center;
  font-size: 1.25rem;
  margin: 10px auto 10px;
}

.video-toolbar-v1 div {
  height: 30px;
  line-height: 30px;
  text-align: left;
}

.video-toolbar-v1 i {
  margin-right: 3px;
}
</style>
