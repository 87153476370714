<template>
  <div>
    <el-row class="top" :gutter="12">
      <el-col :span="12" class="top-left">
        <el-button type="primary" plain @click="dialogVisible=true">新建班级</el-button>
      </el-col>
      <el-col :span="12">
        <el-input
            v-model="se"
            placeholder="请输入班级名称"
            show-word-limit clearable
            prefix-icon='el-icon-search' :style="{width: '100%'}">
          <template slot="append" class="search">
            <div @click="search(1)"> 搜索</div>
          </template>
        </el-input>
      </el-col>
    </el-row>

    <el-row>
      <el-row v-for="(item,index) in crowd" :key="index" class="des" @click.native="toCrowd(item)">
        <el-card class="card" shadow="hover">
          <el-descriptions :title="item.name " :column="3">
            <el-descriptions-item label="学生人数">{{ item.number }}</el-descriptions-item>
            <el-descriptions-item label="班级介绍" contentClassName="intro">{{ item.introduce }}</el-descriptions-item>
            <el-descriptions-item label="创建时间">{{ item.gmtCreate }}</el-descriptions-item>
          </el-descriptions>
        </el-card>
      </el-row>
    </el-row>

    <el-dialog :visible.sync="searchVisible" title="班级信息">
      <el-table :data="se_table.records">
        <el-table-column property="name" label="班级名称" width="200"></el-table-column>
        <el-table-column property="number" label="人数" width="200"></el-table-column>
        <el-table-column property="introduce" label="介绍"></el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="100">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleClick(scope.row)">申请加入班级</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          v-if="se_table"
          background
          :current-page="se_table.current"
          :page-size="se_table.size"
          @current-change="search"
          layout="prev, pager, next"
          :total="se_table.total">
      </el-pagination>
      <div slot="footer">
        <el-button @click="searchVisible = false">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisible" title="班级信息">
      <el-form ref="elForm" :model="temp" :rules="tempRules" size="medium" label-width="100px"
               label-position="left">
        <el-form-item label="班级名称" prop="name">
          <el-input v-model="temp.name" placeholder="请输入班级名称" clearable :style="{width: '100%'}"></el-input>
        </el-form-item>
        <el-form-item label="基本介绍" prop="introduce">
          <el-input v-model="temp.introduce" placeholder="请输入基本介绍" clearable :style="{width: '100%'}"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="handelConfirm">添加班级</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  addCrowd,
  selectCrowdByName,
  selectCrowdByUserId,
  addCrowdInfo
} from "@/api/crowd";
import {isEmpty} from "@/utils/StringUtils";

export default {
  name: "crowd",
  data() {
    return {
      crowd: [],
      temp: {
        name: "",
        introduce: "",
      },
      tempRules: {
        name: [{
          required: true,
          message: '请输入班级名称',
          trigger: 'blur'
        }],
        introduce: [{
          required: true,
          message: '请输入基本介绍',
          trigger: 'blur'
        }],
      },
      dialogVisible: false,
      se: "",
      searchVisible: false,
      se_table: ""
    }
  },
  mounted() {
    this.handleChange();
  },
  methods: {
    handleChange() {
      let uid = this.$store.state.user.id;
      selectCrowdByUserId(uid).then(result => {
        console.log(result)
        this.crowd = result.data.crowd;

      }).catch(error => {
        this.$message(error.message);
      })
    },
    // 添加班级
    handelConfirm() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) return
        console.log("表单验证通过")
        let crowd = {
          name: this.temp.name,
          uid: this.$store.state.user.id,
          introduce: this.temp.introduce
        };
        console.log(crowd)
        addCrowd(crowd).then(result => {
          this.$message(result.data.crowd);
          // 刷新页面的表单信息
          this.handleChange();
        }).catch(error => {
          this.$message(error.message);
        })
      })
    },
    search(current) {
      console.log("点击了搜索按钮");
      if (isEmpty(this.se)) {
        return
      }
      this.searchVisible = true;
      // 开始搜索
      selectCrowdByName(this.se, current).then(result => {
        console.log(result);
        this.se_table = result.data.crowd;
      }).catch(error => {
        this.$message(error.message);
      })
    },
    handleClick(item) {
      let crowdInfo = {
        pid: item.id,
        uid: this.$store.state.user.id,
        status: 1
      }
      console.log(crowdInfo);
      // 添加班级信息
      addCrowdInfo(crowdInfo).then(result => {
        this.$message(result.data.crowd);
      }).catch(error => {
        this.$message(error.message);
      })
    },
    // 跳转到班级详情页
    toCrowd(item) {
      if(item.uid===this.$store.state.user.id){
        this.$router.push({ path: `/crowd/${item.id}/member` })
      }else {
        this.$router.push({ path: `/crowd/${item.id}/member-info` })
      }
    }
  }
}
</script>

<style scoped>
/* 顶部css*/
.top {
  line-height: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.title-span {
  text-align: left;
  max-width: 300px;
  margin-right: 30px;
}

.top-left {
  display: flex;
}

.search {
  background-color: #409EFF !important;
  height: 100%;
  width: 100%;
  color: #ffffff !important;
}

/*  班级类的信息*/
.des {
  margin-bottom: 10px;
}
</style>