<template>
  <div>
    <el-row class="collect" :gutter="5">
      <el-col :span="4">
        <el-menu
            default-active="/self/"
            :router="true"
            class="vertical-demo"
            @open="handleOpen"
            @close="handleClose">
          <el-menu-item index="/self/" >
            <i class="el-icon-document"></i>
            <span slot="title">博客收藏</span>
          </el-menu-item>
          <el-menu-item index="/self/course">
            <i class="el-icon-setting"></i>
            <span slot="title">课程收藏</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col :span="20">
        <router-view></router-view>
      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  name: "collect",
  components: {

  },
  data() {
    return {
      activeName: "first",
      course: "",
      article: ""
    }
  },
  mounted() {

  },
  methods:{
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
  }

}
</script>

<style scoped>
.collect{
  min-height: 400px;
  width: 100%;
  height: 100%;
}
.vertical-demo{
  border: 1px solid #eee;
  min-height: 400px;

}
</style>
